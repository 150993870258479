<template>
  <div class="NavHeader" :class="{ 'NavHeader-no': !noticeBarStatus }">
    <div class="nav">
      <div class="Nav_Left">
        <div class="logo" @click="jump('/')">
          <img v-if="global_info.logo" :src="global_info.logo" alt="" />
        </div>
        <div class="nav-box">
          <div
            class="item"
            @click="jump('/')"
            :class="{ active: this.$route.path == '/Home' }"
          >
            <div class="nav-item-l">
              <span>首页</span>
              <i>HOME GAGE</i>
            </div>
            <div class="nav-item-r none"></div>
            <i class="line"></i>
          </div>
          <div
            class="item"
            @click="jump('/Roll')"
            :class="{ active: this.$route.path == '/Roll' }"
          >
            <div class="nav-item-l">
              <div
                style="
                  height: 0.2rem;
                  margin-top: -0.025rem;
                  margin-bottom: 0.015rem;
                "
              >
                <span style="margin-top: -0.8rem">ROLL房</span>
              </div>
              <i>ROLL ROOM</i>
            </div>
            <div class="nav-item-r none"></div>
            <i class="line"></i>
          </div>
          <div
            class="item"
            @click="goabout"
            :class="{
              active: this.$route.path == '/newuser' && activindex == 4,
            }"
          >
            <div class="nav-item-l">
              <span>分享奖励</span>
              <i>SHARE</i>
            </div>
            <div class="nav-item-r none"></div>
            <i class="line"></i>
          </div>
          <div
            class="item"
            @click="goWarehouse"
            :class="{
              active: this.$route.path == '/newuser' && activindex == 1,
            }"
          >
            <div class="nav-item-l">
              <span>背包</span>
              <i>DEPOT</i>
            </div>
            <div class="nav-item-r none"></div>
            <i class="line"></i>
          </div>
          <div
            class="item"
            @click="gopay"
            :class="{
              active: this.$route.path == '/newuser' && activindex == 3,
            }"
          >
            <div class="nav-item-l">
              <span>支付</span>
              <i>PAY</i>
            </div>
            <div class="nav-item-r none"></div>
            <i class="line"></i>
          </div>
          <!-- <div
            class="item"
            @click="golist"
            :class="{
              active: this.$route.path == '/Ranking' && activindex == 5,
            }"
            v-if="global_info.ranking_open == '1'"
          >
            <div class="nav-item-l">
              <span>排行榜</span>
              <i>RANKING</i>
            </div>
            <div class="nav-item-r none"></div>
            <i class="line"></i>
          </div> -->
        </div>
      </div>
      <div class="personal">
        <div class="Sign" v-if="!IndividualsShow">
          <div class="Sign-item" @click="PostSignInShow">
            <span>请登录</span>
            <span style="margin: 0 4px"> / </span>
            <span>注册</span>
          </div>
        </div>
        <!-- <div class="Sign" v-show="this.IndividualsShow == false">
          <div class="Sign-item">
            <span @click="PostSignInShow">请登录</span>
            <span style="margin: 0 4px"> / </span>
            <span @click="PostRegisterInShow">注册</span>
          </div>
        </div> -->

        <div class="per" v-if="IndividualsShow">
          <div class="user" @click="gouser">
            <img class="user-img" :src="user.avatar" alt="" />
            <div class="">
              <span style="font-size: 0.2rem">{{ user.name }}</span>
              <div
                class="d-flex align-items-center"
                style="font-size: 0.15rem; color: #ecb105; margin-top: 5px"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="T币"
                  placement="bottom"
                  popper-class="my-pop"
                >
                  <Cprice
                    :price="
                      Math.round(
                        (Number(user.bean) + Number(user.integral)) * 100
                      ) / 100
                    "
                    :size="0.18"
                  />
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="积分"
                  placement="bottom"
                  popper-class="my-pop"
                >
                  <div style="margin-left: 0.12rem">
                    <cpoint :size="0.18" :price="Number(user.score)" />
                  </div>
                </el-tooltip>
              </div>
              <el-tooltip
                class="item"
                effect="dark"
                content="弹药"
                placement="bottom"
                popper-class="my-pop"
              >
                <div
                  class="d-flex align-items-center"
                  style="font-size: 0.15rem; color: #ecb105; margin-top: 5px"
                >
                  <img
                    style="width: 0.15rem; margin-right: 0.05rem"
                    src="@/assets/images/about/user/zidan.png"
                    alt=""
                  />
                  <span style="font-size: 0.18rem; font-weight: 600">
                    {{ user.ammunition_energy }}</span
                  >
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <swiper></swiper>
    <div id="NoticeBar"></div>
    <div
      class="SwiperBox-text"
      style="margin: 0px auto; width: 82%; padding-left: 0.55rem"
      v-show="noticeBarStatus"
    >
      <van-notice-bar
        v-if="site_bulletin"
        class="notice-bar"
        color="#ccc"
        mode="closeable"
        scrollable
        @close="noticeBarStatus = false"
      >
        <span style="color: #ecb105">{{ site_bulletin }}</span>
      </van-notice-bar>
    </div>
    <div class="XiangZi_Box" v-if="false">
      <div
        class="XiangZi_item"
        v-for="(item, index) in Box_Arr"
        :key="index"
        @click="ShowNeiRong(item)"
      >
        <div class="pic">
          <img :src="item.intact_cover" alt="" />
        </div>
        <span>${{ item.game_bean }}</span>
        <p>{{ item.name }}</p>
      </div>
    </div>

    <!-- 登录弹框 -->
    <van-overlay @mousewheel.prevent.native="() => {}" :show="SignInShow">
      <div class="Wrapper" v-if="SignInShow">
        <div class="out">
          <div class="out-in-box">
            <div class="close-box" @click="SignInQX">
              <img src="@/assets/images/login/close-new.png" alt="" />
            </div>
            <div class="title">登录TMSKINS</div>
            <div class="login-type-box">
              <div class="login-type">
                <div
                  class="type-btn point"
                  :class="{ 'text-color': PWCode }"
                  @click="PWCode = true"
                >
                  密码登录
                </div>
                <div class="line"></div>
                <div
                  class="type-btn point"
                  :class="{ 'text-color': !PWCode }"
                  @click="PWCode = false"
                >
                  验证码登录
                </div>
              </div>
            </div>
            <div class="form-box login-form-box">
              <el-form
                :model="RegisterForm"
                status-icon
                ref="RegisterForm"
                class="demo-ruleForm"
              >
                <el-form-item prop="mobile" label="">
                  <div class="input-wrap">
                    <el-input
                      v-model="SignInForm.mobile"
                      placeholder="输入手机号"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item v-if="PWCode" prop="password" label="">
                  <div class="input-wrap">
                    <el-input
                      auto-complete="new-password"
                      type="password"
                      v-model="SignInForm.password"
                      placeholder="输入密码"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item v-else prop="verify" label="">
                  <div class="input-wrap code">
                    <el-input
                      v-model="SignInForm.verify"
                      placeholder="输入验证码"
                    ></el-input>
                    <div
                      class="send-text"
                      @click="GetYanZhengMa(2)"
                      v-if="!VerificationShow"
                    >
                      发送
                    </div>
                    <div v-else class="send-text">已发送{{ time }}</div>
                  </div>
                </el-form-item>
                <div class="args">
                  <div>
                    <div
                      @click="argsStatus1 = !argsStatus1"
                      class="args-item point"
                    >
                      <img
                        src="@/assets/images/login/check-new.png"
                        v-show="!argsStatus1"
                        alt=""
                      />
                      <img
                        src="@/assets/images/login/checked-new.png"
                        v-show="argsStatus1"
                        alt=""
                      />
                      我已阅读并同意
                      <span @click="tolist(2)">《用户协议》</span>和
                      <span @click="tolist(3)">《隐私政策》</span>
                    </div>
                    <div
                      @click="argsStatus2 = !argsStatus2"
                      class="args-item point"
                    >
                      <img
                        src="@/assets/images/login/check-new.png"
                        v-show="!argsStatus2"
                        alt=""
                      />
                      <img
                        src="@/assets/images/login/checked-new.png"
                        v-show="argsStatus2"
                        alt=""
                      />
                      确认已满18岁，盲盒娱乐理性消费，请勿上头
                    </div>
                  </div>
                  <div class="regtextbox">
                    没有账号?
                    <span
                      class="point"
                      style="color: #bfbfed"
                      @click="PostRegisterInShow"
                      >点击注册</span
                    >
                  </div>
                </div>
                <div class="rsg-btn-box">
                  <div class="rsg-btn point" @click="PostSignIn">登录</div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 注册弹框 -->
    <van-overlay @mousewheel.prevent.native="() => {}" :show="RegisterShow">
      <div class="Wrapper">
        <div class="out regout">
          <div class="out-in-box">
            <div class="close-box" @click="SignInQX2">
              <img src="@/assets/images/login/close-new.png" alt="" />
            </div>
            <div class="title">注册TMSKINS</div>
            <div class="form-box">
              <el-form
                :model="RegisterForm"
                status-icon
                ref="RegisterForm"
                class="demo-ruleForm"
              >
                <el-form-item prop="name" label="">
                  <div class="input-wrap">
                    <el-input
                      type="name"
                      v-model="RegisterForm.name"
                      placeholder="昵称"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item prop="mobile" label="">
                  <div class="input-wrap">
                    <el-input
                      v-model="RegisterForm.mobile"
                      placeholder="输入手机号"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item prop="verify" label="">
                  <div class="input-wrap code">
                    <el-input
                      v-model="RegisterForm.verify"
                      placeholder="输入验证码"
                      autocomplete="off"
                    ></el-input>
                    <div
                      class="send-text"
                      @click="GetYanZhengMa2(1)"
                      v-if="!VerificationShow"
                    >
                      发送
                    </div>
                    <div v-else class="send-text">已发送{{ time }}</div>
                  </div>
                </el-form-item>
                <el-form-item prop="password" label="">
                  <div class="input-wrap">
                    <el-input
                      auto-complete="new-password"
                      type="password"
                      v-model="RegisterForm.password"
                      placeholder="输入密码"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item prop="password1" label="">
                  <div class="input-wrap">
                    <el-input
                      auto-complete="new-password"
                      type="password"
                      v-model="RegisterForm.password1"
                      placeholder="再次输入密码"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item prop="invite" label="">
                  <div class="input-wrap">
                    <el-input
                      v-model="RegisterForm.invite"
                      placeholder="邀请码"
                    ></el-input>
                  </div>
                </el-form-item>
                <div class="args">
                  <div @click="XYchecked = !XYchecked" class="args-item point">
                    <img
                      src="@/assets/images/login/check-new.png"
                      v-show="!XYchecked"
                      alt=""
                    />
                    <img
                      src="@/assets/images/login/checked-new.png"
                      v-show="XYchecked"
                      alt=""
                    />
                    我已阅读并同意
                    <span @click="tolist(2)">《用户协议》</span>和
                    <span @click="tolist(3)">《隐私政策》</span>
                  </div>
                  <div class="regtextbox">
                    已有账号?
                    <span
                      class="point"
                      style="color: #bfbfed"
                      @click="PostSignInShow"
                      >去登录</span
                    >
                  </div>
                </div>
                <div class="rsg-btn-box">
                  <div class="rsg-btn point" @click="PostRegister">注册</div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 箱子遮罩层 -->
    <van-overlay @mousewheel.prevent.native="() => {}" :show="XZshow">
      <div class="wrapper">
        <div class="block">
          <div class="block-T">
            <p>{{ Box_Name }}</p>
            <span @click="CloseXZshow">×</span>
          </div>
          <div class="Box-List">
            <div
              class="Box-List-item"
              v-for="(item, index) in JiangPin_Arr"
              :key="index"
            >
              <div class="pic">
                <div class="zhao">
                  <img :src="item.lv_bg_image" alt="" />
                </div>
                <img :src="item.cover" alt="" />
              </div>
              <div class="name">{{ item.name | GuoLv }}</div>
              <div class="money">
                <img src="@/assets/images/PublicImg/JinBi.png" alt="" /><span>{{
                  item.bean
                }}</span>
              </div>
            </div>
          </div>
          <div class="boxbtn">
            <p @click="CloseXZshow">确定</p>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 排行 -->
    <Rank :class="{ hidden: !isVisible, visible: isVisible }" />
    <!-- 高光时刻弹窗 -->
    <PopupIndex />
  </div>
</template>

<script>
import {
  Register,
  PostSendSms,
  SignIn,
  PersonalInfo,
  Info,
} from "@/network/api.js";
import { mapState, mapMutations, mapActions } from "vuex";
import Swiper from "@/components/common/NavHeader/Swiper.vue";
import Rank from "@/views/Rank/index.vue";
import PopupIndex from "@/components/Highlight/popupIndex.vue";
export default {
  name: "NavHeader",
  data() {
    return {
      argsStatus1: false,
      argsStatus2: false,
      W_BL: false,
      Box_Name: "",
      time: 60,
      VerificationShow: false,
      Box_Arr: [],
      checked: true,
      XYchecked: false,
      JiangPin_Arr: [],
      site_bulletin: "",
      Box_Arr_Show: false,
      XZshow: false,
      RegisterForm: {
        name: "",
        mobile: "",
        password: "",
        password1: "",
        verify: "",
        invite: "",
      },

      SignInForm: {
        mobile: "",
        password: "",
        verify: "",
      },
      SuiJiNum: 0,
      info: {},
      // 验证码密码切换
      PWCode: true,
      currentIndex: 0,
      noticeBarStatus: true,

      activindex: null, //个人中心跳转选中

      isVisible: false, // 控制元素是否可见的响应式数据
      targetPosition: 400, // 目标元素的滚动位置
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateActiveSection);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.updateActiveSection);
  },
  created() {
    this.MyInfo();
    if (window.screen.width < 750) {
      this.W_BL = true;
    } else {
      this.W_BL = false;
    }
    if (
      this.$route.path == "/BoxFight" ||
      this.$route.path == "/vsbox" ||
      this.$route.path == "/CreateAConfrontation"
    ) {
      this.Box_Arr_Show = true;
    }

    this.PanDuanDengLu();
    this.GetInfo();
    this.GetInfo2();
    console.log(this.$route.path, "afaf");

    if (this.$route.path == "/Home") {
      this.targetPosition = 400;
    } else if (this.$route.path == "/OpeningBox") {
      this.targetPosition = 400;
    } else {
      this.targetPosition = 0;
    }
    this.updateActiveSection();
  },

  filters: {
    GuoLv(str) {
      return str.length > 12 ? str.substr(0, 10) + "..." : str;
    },
    STY(val) {
      if (val == true) {
        return "width:80%;";
      } else {
        return "width:5rem;";
      }
    },
  },
  methods: {
    tolist(v) {
      this.GetRegisterShow(false);
      this.GetSignInShow(false);
      this.$router.push({
        path: "/Datapage",
        query: {
          id: v,
        },
      });
    },
    updateActiveSection() {
      if (this.targetPosition == 400) {
        // 监听滚动事件
        if (window.pageYOffset > this.targetPosition) {
          this.isVisible = true;
        } else {
          this.isVisible = false;
        }
      } else {
        this.isVisible = true;
      }
    },
    ...mapActions({
      MyInfo: "Refresh",
    }),
    ...mapMutations([
      "User",
      "GetIndividualsShow",
      "Leave",
      "GetRegisterShow",
      "GetSignInShow",
      "setGlobalInfo",
    ]),
    sendSmsTime() {
      if (this.Timer) {
        clearInterval(this.Timer);
      }
      this.time = 60;
      this.VerificationShow = true;
      this.Timer = setInterval(() => {
        this.time -= 1;
        if (this.time < 1) {
          this.VerificationShow = false;
        }
      }, 1000);
    },
    gouser() {
      this.activindex = null;
      this.$router.push({
        path: "/newuser",
        query: {
          TabIndex: 2,
        },
      });
    },
    // 去分享验证
    goabout() {
      this.activindex = 4;
      if (!this.user.id) {
        this.$message({
          message: "未登录，请登录后操作",
          type: "error",
        });
        this.PostSignInShow();
      } else {
        this.$router.push({
          path: "/newuser",
          query: {
            TabIndex: 4,
          },
        });
      }
    },
    // 去背包验证
    goWarehouse() {
      this.activindex = 1;
      if (!this.user.id) {
        this.$message({
          message: "未登录，请登录后操作",
          type: "error",
        });
        this.PostSignInShow();
      } else {
        this.$router.push({
          path: "/newuser",
          query: {
            TabIndex: 1,
          },
        });
      }
    },
    golist() {
      this.activindex = 5;
      this.$router.push({
        path: "/Ranking",
      });
    },

    gopay() {
      this.activindex = 3;
      // 进入支付页面判断是否登录
      if (!this.user.id) {
        this.$message({
          message: "未登录，请登录后操作",
          type: "error",
        });
        this.PostSignInShow();
      } else {
        this.$router.push({
          path: "/newuser",
          query: {
            TabIndex: 3,
          },
        });
      }
    },
    //初次进入判断是否记住登录
    PanDuanDengLu() {
      if (localStorage.getItem("token")) {
        this.GetPersonalInfo();
      } else {
        this.GetIndividualsShow(false);
      }
    },

    //站点信息
    GetInfo2() {
      Info().then((res) => {
        this.info = res.data.data;
        localStorage.setItem("static_file_url", res.data.data.static_file_url);
        localStorage.setItem("website_icp", res.data.data.website_icp);
        this.GetSuiJi();
      });
    },

    GetInfo() {
      Info().then((res) => {
        this.site_bulletin = res.data.data.site_bulletin;
        this.setGlobalInfo(res?.data?.data || {});
      });
    },

    GetSuiJi() {
      this.SuiJiNum =
        Math.floor(Math.random() * (50 - Number(this.info.user_number))) +
        Number(this.info.user_number);
    },

    CloseXZshow() {
      this.XZshow = false;
    },

    //暂未开放
    WuKaiFang() {
      this.$message({
        message: "暂未开放,敬请期待!",
        type: "error",
      });
    },

    //查看箱子内容
    ShowNeiRong(item) {
      this.JiangPin_Arr = item.box_awards;
      this.Box_Name = item.name;
      this.XZshow = true;
    },

    //路由跳转
    jump(str) {
      this.$router
        .push({
          path: str,
        })
        .catch((err) => {
          this.$router.go(0);
        });
    },

    //修改密码
    // GetChangePassword() {
    //   this.$router.push("/ChangePassword");
    //   this.GetSignInShow(true);
    // },

    //注册操作
    PostRegister() {
      if (this.XYchecked) {
        if (this.RegisterForm.password !== this.RegisterForm.password1) {
          this.$message({
            message: "两次输入密码不一致，请检查后重新输入",
            type: "error",
          });
          return false;
        }
        Register(
          this.RegisterForm.mobile,
          this.RegisterForm.password,
          this.RegisterForm.name,
          this.RegisterForm.verify,
          this.RegisterForm.invite
        ).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "注册成功",
              type: "success",
            });
            this.GetRegisterShow(false);
            this.EmptyForm();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          message: "请先勾选用户协议",
          type: "error",
        });
      }
    },

    //发送手机号验证码
    GetYanZhengMa(num) {
      if (this.RegisterForm.mobile || this.SignInForm.mobile) {
        // this.VerificationShow = true;
        // this.$refs.countDown.start();
        PostSendSms(
          this.RegisterForm.mobile || this.SignInForm.mobile,
          num
        ).then((res) => {
          if (res.data.code == 200) {
            this.sendSmsTime();
            this.$message({
              message: "发送成功",
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },
    //注册获取验证码
    GetYanZhengMa2(num) {
      console.log(123, this.RegisterForm);
      if (this.RegisterForm.mobile) {
        // this.VerificationShow = true;
        // this.$refs.countDown.start();
        PostSendSms(this.RegisterForm.mobile, num).then((res) => {
          if (res.data.code == 200) {
            this.sendSmsTime();
            this.$message({
              message: "发送成功",
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },

    finish() {
      //重置倒计时
      this.VerificationShow = false;
      this.$refs.countDown.reset();
    },

    //取消注册
    RegisterQX() {
      this.GetRegisterShow(false);
      this.EmptyForm();
    },

    //登录
    PostSignInShow() {
      this.GetSignInShow(true);
      this.GetRegisterShow(false);
    },
    //注册
    PostRegisterInShow() {
      this.GetRegisterShow(true);
      this.GetSignInShow(false);
    },

    //登录操作
    PostSignIn() {
      if (!this.argsStatus1 || !this.argsStatus2) {
        this.$message.error(
          "请确认已满18周岁，并同意《用户协议》和《隐私条款》!"
        );
        return;
      }
      SignIn(
        this.SignInForm.mobile,
        this.PWCode ? this.SignInForm.password : "",
        this.PWCode ? "" : this.SignInForm.verify
      ).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "登录成功",
            type: "success",
          });
          localStorage.setItem(
            "token",
            "Bearer" + " " + res.data.data.access_token
          );
          this.GetSignInShow(false);
          this.GetPersonalInfo();
          this.EmptySignInForm();
          this.MyInfo();
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },

    //个人信息
    GetPersonalInfo() {
      console.log("获取用户信息GetIndividualsShow111");
      PersonalInfo().then((res) => {
        if (res.data?.code === 200) {
          console.log("获取用户信息GetIndividualsShow222", res);
          localStorage.setItem("boxUserInfo", JSON.stringify(res.data.data));
          console.log("获取用户信息GetIndividualsShow333");
          this.GetIndividualsShow(true);
        } else {
          this.$message.error(res.data?.message);
          this.GetIndividualsShow(false);
        }
      });
    },

    //取消登录
    SignInQX() {
      this.EmptySignInForm();
      this.GetSignInShow(false);
    },
    //取消注册
    SignInQX2() {
      this.EmptySignInForm();
      this.GetRegisterShow(false);
    },

    //个人下拉菜单
    handleCommand(command) {
      switch (command) {
        case "out":
          this.SignOut();
          break;
        default:
          this.$router.push(command);
      }
    },

    //退出
    SignOut() {
      this.GetIndividualsShow(false);
      this.Leave();
    },

    //清空注册表单
    EmptyForm() {
      // this.RegisterForm.email = "";
      this.RegisterForm.name = "";
      this.RegisterForm.mobile = "";
      this.RegisterForm.password = "";
      this.RegisterForm.password1 = "";
      this.RegisterForm.verify = "";
      this.RegisterForm.invite = "";
      if (this.$route.query?.IvtCode) {
        this.RegisterForm.invite = this.$route.query?.IvtCode;
      }
    },

    //清空登录表单
    EmptySignInForm() {
      // this.SignInForm.email = "";
      // this.SignInForm.pass = "";
      this.VerificationShow = false;
    },

    //路由跳转充值
    ToRecharge() {
      this.$router.push("/Recharge");
    },
    doLogin(e) {
      if (e.keyCode === 13 || e.key === "Enter") {
        this.PostSignIn();
      }
    },
    doRegister(e) {
      if (e.keyCode === 13 || e.key === "Enter") {
        this.PostRegister();
      }
    },
  },

  computed: {
    ...mapState([
      "global_info",
      "user",
      "IndividualsShow",
      "RegisterShow",
      "SignInShow",
    ]),
  },

  watch: {
    RegisterShow(newVal) {
      this.EmptyForm();
      console.log(33333, newVal);
      if (newVal) {
        addEventListener("keydown", this.doRegister);
      } else {
        removeEventListener("keydown", this.doRegister);
      }
    },
    SignInShow(newVal) {
      console.log(33333, newVal);
      if (newVal) {
        addEventListener("keydown", this.doLogin);
      } else {
        removeEventListener("keydown", this.doLogin);
      }
    },
    Email_Name(val) {
      this.Email_Name = val;
    },

    $route(to, from) {
      if (
        to.path == "/BoxFight" ||
        to.path == "/vsbox" ||
        to.path == "/CreateAConfrontation"
      ) {
        this.Box_Arr_Show = true;
      } else {
        this.Box_Arr_Show = false;
      }
      if (to.path == "/Home") {
        this.targetPosition = 400;
      } else if (this.$route.path == "/OpeningBox") {
        this.targetPosition = 400;
      } else {
        this.targetPosition = 0;
      }
      this.updateActiveSection();
    },
  },

  components: {
    Swiper,
    Rank,
    PopupIndex,
  },
};
</script>

<style lang="scss">
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  -webkit-text-fill-color: #fff !important;
  transition: background-color 5000s ease-in-out 0s !important;
  font-size: 0.12rem !important;
}
.NavHeader {
  width: 100%;
  background: rgba(120, 120, 120, 0.6);
  position: relative;
  margin-bottom: 0.4rem;
  .XiangZi_Box {
    width: 100%;
    display: flex;

    .XiangZi_item {
      width: 1.92rem;
      height: 2.3rem;
      background: rgba(25, 25, 25, 0.6);
      border: 1px solid #2b2b2b;
      margin-left: -1px;
      cursor: pointer;
      text-align: center;
      position: relative;
      box-sizing: border-box;

      .pic {
        width: 1.6rem;
        height: 1.6rem;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          vertical-align: middle;
          max-width: 80%;
          max-height: 80%;
        }
      }

      span {
        img {
          width: 0.2rem;
          height: 0.2rem;
          vertical-align: middle;
        }

        font-size: 0.14rem;
        color: #ffd300;
      }

      p {
        margin-top: 0.08rem;
        font-size: 0.14rem;
      }

      &:hover {
        border-color: #ffd300;
        z-index: 5;

        p {
          color: #ffd300;
        }
      }
    }
  }

  .nav {
    width: 100%;
    height: 0.9rem;
    // background: linear-gradient(#0d2950, #091f3e, #05152b, #031226, #031226);
    // background: #0b2549;
    background: #041c33;
    // position: fixed;
    // left: 0;
    // top: 0;
    // z-index: 99;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;

    .Nav_Left {
      display: flex;
      align-items: center;

      .logo {
        // width: 2rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 0.3rem;

        img {
          width: 2rem;
        }
      }

      .nav-box {
        box-sizing: border-box;
        display: flex;
        align-items: center;

        .item {
          width: 1.3rem;
          height: 0.9rem;
          line-height: 0.9rem;
          box-sizing: border-box;
          text-align: center;
          cursor: pointer;
          position: relative;
          margin-right: 0.2rem;
          position: relative;
          display: flex;
          justify-content: center;

          .nav-item-l {
            display: flex;
            flex-direction: column;
            height: 100%;

            span {
              height: 22%;
              font-size: 0.18rem;
              font-weight: 600;
            }

            i {
              font-size: 0.1rem;
              color: #485364;
              height: 78%;
              white-space: nowrap;
            }
          }

          .nav-item-r {
            width: 0.06rem;
            height: 0.06rem;
            background: #fff;
            border-radius: 50%;
            align-self: center;
            margin-left: 0.2rem;
          }

          .line {
            display: block;
            width: 100%;
            height: 0.04rem;
            position: absolute;
            background: #7581eb;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            opacity: 0;
          }

          &:hover {
            background: rgba($color: #fff, $alpha: 0.03);
          }

          &.active {
            background: #232749;

            .nav-item-l {
              span {
                color: #ff6600;
                font-weight: 600;
              }
            }

            .nav-item-r {
              background: #ff6600;
              display: none;
            }

            i {
              opacity: 1;
            }
          }

          .drop {
            display: none;
            position: absolute;
            width: 1.95rem;
            height: 2.18rem;
            bottom: -2.06rem;
            background: url(../../../assets/images/NavHeader/drop.png) no-repeat;
            background-size: contain;
            z-index: 10;
            padding: 0.2rem;
            box-sizing: border-box;

            .drop-item {
              font-size: 0.14rem;
              display: flex;
              width: 100%;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              padding-bottom: 0.12rem;
              margin-top: 0.03rem;
              img {
                display: block;
                margin-right: 0.1rem;
              }

              div {
                // width: 80px;
                letter-spacing: 0.05rem;
                height: 0.35rem;
                line-height: 0.35rem;
                color: #666666;
                font-size: 0.16rem;
              }

              &:hover {
                background: url(../../../assets/images/NavHeader/drop-line-ac.png)
                  no-repeat;
                background-size: contain;
                background-position: bottom;
                div {
                  color: #ead18f;
                }
              }
            }

            .drop-item-bl {
              background: url(../../../assets/images/NavHeader/drop-line.png)
                no-repeat;
              background-size: contain;
              background-position: bottom;
            }

            .drop-item-bl-ac {
              background: url(../../../assets/images/NavHeader/drop-line-ac.png)
                no-repeat;
              background-size: contain;
              background-position: bottom;
              color: #ead18f;

              div {
                color: #ead18f;
              }
            }
          }
        }

        .gameClass {
          &:hover {
            .drop {
              display: block;
            }
          }
        }

        .nav-pay-box {
          display: flex;
          width: 2.2rem;
          border: 1px solid #3582fb;
          height: 0.5rem;
          position: relative;
          background: linear-gradient(#0d1b2b, #0a121b, #1d2e43);
          cursor: pointer;

          .item-pay,
          .item-package {
            // font-size: 0.17rem;
            font-size: 0.2rem;
          }

          &::before,
          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.15rem 0.15rem 0 0;
            border-color: #007bff transparent transparent transparent;
          }

          &::before {
            right: 0;
            bottom: 0;
            transform: rotate(180deg);
          }

          div {
            box-sizing: border-box;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          i {
            width: 0.02rem;
            height: 0.3rem;
            background: #354663;
            align-self: center;
          }
        }
      }
    }

    .personal {
      height: 100%;
      margin-left: 0.2rem;

      .per {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        padding-right: 30px;
        background-size: 100% 100%;

        p {
          width: 1.28rem;
          height: 100%;
          text-align: center;
          color: #75cd66;
          font-size: 0.18rem;
          font-weight: bold;
          border-left: 1px solid rgba($color: #fff, $alpha: 0.08);
          margin: 0;

          img {
            display: block;
            margin: 0.2rem auto 0.05rem;
            width: 24px;
            height: 24px;
          }
        }

        .user {
          width: 100%;
          height: 100%;
          padding-left: 30px;
          display: flex;
          align-items: center;

          .user-img {
            display: block;
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
            border: 1px solid #ffba2e;
            margin: 0.05rem 0.1rem;
          }

          span {
            font-size: 0.14rem;
          }
        }
      }

      .Sign {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .Sign-item {
          // width: 0.4rem;
          height: 0.3rem;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.04rem;
          text-align: center;
          line-height: 0.3rem;
          color: #fff;

          p {
            font-size: 0.16rem;
          }
        }
      }
    }
  }

  .SwiperBox-text {
    position: absolute;
    top: 2.4rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
    color: #fff;
    opacity: 0.6;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .img {
      // position: absolute;
      // top: 50%;
      // bottom: -0.06rem;
      // left: 2%;
      // z-index: 2;
      width: 0.15rem;
      height: 0.15rem;
      // margin-left: 0.15rem;
      // transform: translateY(-60%);
    }

    .notice-bar {
      // margin: -0.3rem 0;
      font-size: 0.14rem;
      border-radius: 1rem;
      border: 0.03rem solid #1e3548;
      // background: #171717;
      padding-left: 0.5rem;
      flex-grow: 1;
      background: url("../../../assets/images/NavHeader/11.png") no-repeat
        center;
      background-size: 0.22rem 0.18rem;
      background-position-x: 0.2rem;
    }
    .van-notice-bar {
      height: 0.35rem;
      margin-top: 0.02rem;
    }
  }

  .data_info {
    width: 100%;
    padding: 0 0.6rem 0.2rem;
    box-sizing: border-box;
    height: 0.6rem;
    display: flex;
    align-items: center;

    .data_info_item {
      display: flex;
      align-items: center;
      height: 0.6rem;
      margin-right: 0.2rem;

      img {
        height: 0.4rem;
        margin-right: 0.05rem;
      }

      p {
        font-size: 0.22rem;
        color: #a99bc5;

        span {
          color: #ea4391;
          margin-right: 0.04rem;
        }
      }
    }
  }

  .Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;

    .out {
      // display: flex;
      width: 7.87rem;
      min-height: 3.75rem;
      // height: 8.06rem;
      background: url(../../../assets/images/login/login-bg-new.png);
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .out-in-box {
        position: relative;
        width: 6.51rem;
        min-height: 3.75rem;
        .login-type-box {
          padding: 0.14rem 1.41rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .login-type {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.69rem;
          .type-btn {
            flex-grow: 1;
            text-align: center;
            font-size: 0.14rem;
            color: #909090;
          }
          .text-color {
            color: #fff;
          }
          .line {
            width: 0.02rem;
            height: 0.165rem;
            background: rgba(49, 49, 87, 1);
          }
        }
        .close-box {
          width: 0.16rem;
          position: absolute;
          right: 0.2rem;
          top: 0.2rem;
          img {
            width: 100%;
            cursor: pointer;
          }
        }
        .title {
          width: 100%;
          height: 0.74rem;
          line-height: 0.74rem;
          text-align: center;
          font-size: 0.24rem;
        }
        .form-box {
          padding: 0 1.41rem;
          padding-top: 0.36rem;
          .demo-ruleForm {
            .el-form-item {
              all: unset;
            }
            .input-wrap {
              width: 100%;
              height: 0.34rem;
              padding: 0.08rem 0.12rem;
              background: url(../../../assets/images/login/form-item-bg.png);
              background-size: 100% 100%;
              font-size: 0.16rem;
              color: #fff;
              margin-bottom: 0.2rem;
              display: flex;
              align-items: center;

              .el-input {
                all: unset;
                display: block;
                width: 100%;
                height: 100%;
              }

              .el-input__inner::placeholder {
                color: rgba(191, 191, 237, 0.5);
              }
              .el-input__inner {
                all: unset;
                display: block;
                width: 100%;
                height: 100%;
                line-height: unset;
              }
              .send-text {
                width: 0.62rem;
                height: 100%;
                border-left: 0.02rem solid rgba(50, 50, 93, 1);
                font-size: 0.1rem;
                color: rgba(191, 191, 237, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              }
            }
            .args {
              margin-bottom: 0.38rem;
              display: flex;
              justify-content: space-between;

              .args-item {
                display: flex;
                align-items: center;
                font-size: 0.1rem;
                color: #bfbfed;
                margin-bottom: 0.08rem;
                img {
                  margin-right: 0.06rem;
                  width: 0.1rem;
                }
              }
              .regtextbox {
                font-weight: 300;
                font-size: 0.12rem;
                color: #ffffff;
              }
            }
            .rsg-btn-box {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 0.3rem;
              .rsg-btn {
                width: 1.76rem;
                height: 0.4rem;
                background: url(../../../assets/images/login/btn-bg.png);
                background-size: 100% 100%;
                font-size: 0.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
        .login-form-box {
          padding-top: 0;
        }
      }
    }
    .regout {
      background: url(../../../assets/images/login/rsg-bg.png);
      background-size: 100% 100%;
      width: 7.87rem;
      min-height: 5.32rem;
      .out-in-box {
        width: 6.51rem;
        min-height: 5.32rem;
      }
    }

    .DZ-box {
      color: #fff;
      position: relative;
      width: 100%;
      margin-top: 0.24rem;

      /*border-top: 3px solid #7581EB;*/
      .active-login {
        display: flex;
        width: 100%;
        height: 0.54rem;
        background: url(../../../assets/images/login/pass-login.png) no-repeat;
        background-size: 100% 100%;
        width: 5.52rem;
        margin: 0 auto 0.53rem;

        span {
          width: 50%;
          height: 100%;
          font-size: 0.3rem;
          color: #fff;
          font-weight: 400;
          text-align: center;
          line-height: 0.54rem;

          &:nth-child(2) {
            color: #8f9195;
          }
        }
      }

      .active-login-ac {
        background: url(../../../assets/images/login/code.png) no-repeat;
        background-size: 100% 100%;

        span {
          &:nth-child(1) {
            color: #8f9195;
          }

          &:nth-child(2) {
            color: #fff;
          }
        }
      }

      .input-wrap {
        display: flex;
        align-items: center;
        background: rgba(0, 0, 51, 0.36);
        border: 0.01rem solid #3399ff;
        box-shadow: 0px 0px 1px 1px #3399ff;
        box-sizing: border-box;
        padding-left: 0.1rem;
        height: 0.57rem;

        .phone-icon {
          width: 0.3rem;
          height: 0.32rem;
        }
      }

      .Steam {
        width: 100%;
        padding-left: 0.9rem;
        text-align: center;
        box-sizing: border-box;
        margin-bottom: 0.2rem;

        p {
          font-size: 0.17rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #3399ff;
        }

        .img_box {
          margin: 0.2rem auto 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.17rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #0066cc;

          img {
            width: 0.4rem;
            height: 0.4rem;
            margin-right: 0.12rem;
          }

          cursor: pointer;
        }
      }

      .login-tips {
        font-size: 0.17rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #0066cc;
        text-align: center;

        p {
          width: fit-content;
          margin-left: 2.4rem;
          line-height: 1.5;
        }
      }

      .el-form {
        width: 90%;
        margin-top: 0.2rem;

        .remember_box {
          width: 100%;
          height: 0.2rem;
          display: flex;
          padding-left: 0.9rem;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          margin-bottom: 0.2rem;

          p {
            line-height: 0;
            margin-top: 0;
            font-size: 0.14rem;
            cursor: pointer;

            &:hover {
              color: red;
            }
          }

          .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #ffd322;
          }

          .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            border-color: #ffd322;
            background: #ffd322;
          }

          .el-checkbox {
            color: #000;
            font-size: 0.14rem;
          }
        }

        .el-form-item__label {
          color: #fff;
          font-size: 0.14rem;
        }

        .el-input {
          // height: 0.55rem;
          .el-input__inner {
            background: #002154 !important;
            border-radius: 0;
            border: none;
            color: #fff;
            height: 0.55rem;
          }

          input:-webkit-autofill,
          textarea:-webkit-autofill,
          select:-webkit-autofill {
            -webkit-text-fill-color: #fff;
            box-shadow: 0 0 0px 50px #002154 inset !important;
          }
        }
      }

      .YZM-box {
        .el-form-item__content {
          display: flex;
          align-items: center;

          .el-input {
            width: 70%;
          }

          .YZM {
            position: absolute;
            top: -0rem;
            right: 0;
            width: 2.5rem;
            height: 0.66rem;
            transform: translateY(-0.05rem);
            margin-left: 0.3rem;
            font-size: 0.26rem;
            line-height: 0.66rem;
            text-align: center;
            background: url(../../../assets/images/about/reward/btn.png)
              no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
            color: #fff;
          }

          .DJS {
            position: absolute;
            top: -0rem;
            right: 0;
            width: 2.2rem;
            height: 0.66rem;
            transform: translateY(-0.05rem);
            width: 2.2rem;
            height: 0.66rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.3rem;
            background: url(../../../assets/images/about/reward/btn.png)
              no-repeat;
            background-size: 100% 100%;

            .XXX {
              height: 0.38rem;
              font-size: 0.26rem;
              margin-right: 0.05rem;
              box-sizing: border-box;
              margin-bottom: 0.02rem;
              line-height: 0.38rem;
            }

            .CLR {
              font-size: 0.26rem;
              color: #fff;
              line-height: 0.66rem;
            }
          }
        }
      }

      .code {
        width: 71%;
        position: relative;
        padding-right: 0.2rem;
      }

      .args {
        width: 100%;
        margin: 0 auto;
        padding-left: 2rem;

        .args-item {
          display: flex;
          align-items: center;
          margin-bottom: 0.24rem;
          font-size: 0.17rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #0066cc;

          img {
            width: 0.3rem;
            margin-right: 0.03rem;
            cursor: pointer;
          }
        }
      }

      .btn-box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.17rem;

        .btn {
          width: 2.22rem;
          height: 0.67rem;
          background: url(../../../assets/images/login/btn.png) no-repeat;
          background-size: 100% 100%;
          color: #000;
          text-align: center;
          line-height: 0.67rem;
          margin: 0 0.1rem;
          border-radius: 0.04rem;
          cursor: pointer;
          font-size: 0.26rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #cccccc;
        }
      }

      .XY_box {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        .el-checkbox__label {
          color: #ffd322;
        }

        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          border-color: #ffd322;
          background: #ffd322;
        }
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 8rem;
    height: 6.7rem;
    background-color: #212529;
    border-radius: 0.06rem;
    padding: 0.2rem;
    box-sizing: border-box;

    .block-T {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 0.24rem;
        color: #fff;
      }

      span {
        font-size: 0.28rem;
        color: rgba($color: #ffffff, $alpha: 0.6);
        cursor: pointer;
      }
    }

    .Box-List {
      width: 100%;
      height: 5rem;
      margin: 0.2rem 0 0.4rem;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;

      .Box-List-item {
        width: 1.8rem;
        height: 2.3rem;
        position: relative;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 0.04rem;
        margin-right: 0.1rem;
        margin-bottom: 0.1rem;
        cursor: pointer;
        overflow: hidden;

        &:nth-child(4n) {
          margin-right: 0;
        }

        &:hover {
          .ceng {
            opacity: 1;
          }
        }

        .pic {
          width: 1.8rem;
          height: 1.5rem;
          margin: 0.1rem auto 0;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .zhao {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          img {
            max-width: 100%;
            max-height: 100%;
            position: relative;
            z-index: 10;
          }
        }

        .name {
          width: 100%;
          text-align: center;
          color: #9e9e9e;
          font-size: 0.14rem;
        }

        .money {
          width: 100%;
          margin-top: 0.1rem;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 0.2rem;
            height: 0.2rem;
            vertical-align: middle;
          }

          span {
            font-size: 0.14rem;
            color: #ffd300;
          }
        }
      }
    }

    .boxbtn {
      width: 100%;
      height: 0.35rem;
      display: flex;
      justify-content: flex-end;

      p {
        width: 0.7rem;
        height: 0.35rem;
        background: red;
        font-size: 0.14rem;
        color: #fff;
        text-align: center;
        line-height: 0.35rem;
        cursor: pointer;
      }
    }
  }
}
.NavHeader-no {
  margin-bottom: 0rem;
}

.NavHeader-bg {
  margin-top: 0.9rem;
}
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s linear;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.my-pop.el-tooltip__popper.is-dark {
  background: rgba(8, 8, 30, 0.9);
  border-radius: 0.04rem;
  border: 0.01rem solid #5d5d9b;
  font-weight: 400;
  font-size: 0.12rem;
  color: #c5bbed;

  //三角形颜色修改
  &[x-placement^="bottom"] .popper__arrow:after {
    border-bottom-color: rgba(8, 8, 30, 0.9);
  }
  &[x-placement^="bottom"] .popper__arrow {
    border-bottom-color: #5d5d9b;
  }
}
</style>
