<template>
  <div id="RANK">
    <!-- 选中区域 -->
    <div class="iconbox">
      <div class="icon point">
        <div
          class="iconcenter"
          style="margin-bottom: 0.05rem"
          @click="tocdkbox"
        >
          <img
            class="jump"
            style="width: 1.48rem; height: 1.48rem"
            src="../../assets/images/Rank/iconbox.png"
            alt=""
          />
        </div>
      </div>
      <div class="icon point" @click="toMail">
        <div
          class="iconcenter"
          @mouseenter="acchage(6)"
          @mouseleave="
            () => {
              this.active = null;
            }
          "
        >
          <img
            v-show="active != 6"
            src="../../assets/images/Rank/icon7.png"
            alt=""
          />
          <img
            v-show="active == 6"
            src="../../assets/images/Rank/icon7-7.png"
            alt=""
          />
        </div>
        <div v-if="noneReadNum > 0" class="tipsicon flex-c">
          {{ noneReadNum > 99 ? "99+" : noneReadNum }}
        </div>
        <p :style="active == 6 ? ' color: #BF8D0B;' : ''">邮件中心</p>
      </div>
      <div
        class="icon point"
        @click="
          () => {
            (this.welfare = true), (this.Dailywelfare = true);
          }
        "
      >
        <div
          class="iconcenter"
          @mouseenter="acchage(1)"
          @mouseleave="
            () => {
              this.active = null;
            }
          "
        >
          <img
            v-show="active != 1"
            src="../../assets/images/Rank/icon6.png"
            alt=""
          />
          <img
            v-show="active == 1"
            src="../../assets/images/Rank/icon6-6.png"
            alt=""
          />
        </div>
        <p :style="active == 1 ? ' color: #BF8D0B;' : ''">红包中心</p>
      </div>
      <a
        @mouseenter="joincodeenter"
        @mouseleave="joincodeleve"
        target="_blank"
        :href="querydata.qq_group_link"
      >
        <div
          class="icon point"
          @mouseenter="acchage(2)"
          @mouseleave="
            () => {
              this.active = null;
            }
          "
        >
          <div class="iconcenter">
            <img
              v-show="active != 2"
              src="../../assets/images/Rank/icon2.png"
              alt=""
            />
            <img
              v-show="active == 2"
              src="../../assets/images/Rank/icon2-2.png"
              alt=""
            />
          </div>
          <p :style="active == 2 ? ' color: #BF8D0B;' : ''">一键加群</p>
        </div>
      </a>
      <div
        class="icon point"
        @mouseenter="acchage(3)"
        @mouseleave="
          () => {
            this.active = null;
          }
        "
        @click="touser"
      >
        <div class="iconcenter">
          <img
            v-show="active != 3"
            src="../../assets/images/Rank/icon3.png"
            alt=""
          />
          <img
            v-show="active == 3"
            src="../../assets/images/Rank/icon3-3.png"
            alt=""
          />
        </div>
        <p :style="active == 3 ? ' color: #BF8D0B;' : ''">取回助手</p>
      </div>
      <!-- <a @mouseenter="joincodeenterkefu" @mouseleave="joincodelevekefu" target="_blank" :href="`http://wpa.qq.com/msgrd?v=3&uin=${querydata.service_qq}&site=qq&menu=yes`"> -->
      <a
        target="_blank"
        href="https://kefu.tmskins.com/index/index/home?theme=7571f9&visiter_id=&visiter_name=&avatar=&business_id=1&groupid=0"
      >
        <div class="icon point">
          <div class="iconcenter">
            <img
              v-show="active != 4"
              src="../../assets/images/Rank/icon4.png"
              alt=""
            />
            <img
              v-show="active == 4"
              src="../../assets/images/Rank/icon4-4.png"
              alt=""
            />
          </div>
          <p :style="active == 4 ? ' color: #BF8D0B;' : ''">在线客服</p>
        </div>
      </a>
      <a>
        <div
          class="icon point"
          @mouseenter="acchage(5)"
          @mouseleave="
            () => {
              this.active = null;
            }
          "
          @click="totop"
        >
          <div class="iconcenter">
            <img
              class="topimg"
              v-show="active != 5"
              src="../../assets/images/Rank/icon5.png"
              alt=""
            />
            <img
              class="topimg"
              v-show="active == 5"
              src="../../assets/images/Rank/icon5-5.png"
              alt=""
            />
          </div>
          <p :style="active == 5 ? ' color: #BF8D0B;' : ''">返回顶部</p>
        </div>
      </a>
    </div>
    <div class="joincode" v-show="join">
      <img :src="querydata.qq_group_qr_code" alt="" />
    </div>
    <!-- <div class="joincode" v-show="joinkefu">
      <img :src="querydata.service_qr_code" alt="" />
    </div> -->
    <!-- 福利中心弹出 -->
    <van-overlay :show="welfare">
      <div class="duwelfare" v-if="welfare">
        <div class="title">
          <div class="center">活动福利</div>
          <img
            class="point"
            style="
              display: block;
              width: 0.16rem;
              position: absolute;
              right: 0.2rem;
            "
            @click="
              () => {
                (this.welfare = false),
                  (this.flbtnclick = 2),
                  (this.changeerjibtn = 1);
              }
            "
            src="../../assets/images/mall/pc-mall-close.png"
            alt=""
          />
        </div>
        <div class="line"></div>
        <!-- 活动主体 -->
        <div class="welfaremain">
          <!--左边按钮区域 -->
          <div class="l_btn point">
            <!-- <div class="btnbox" :class="flbtnclick === 1 ? 'acbtnbox' : ''" @click="changebtn(1)"
                            style="margin-top: 0.4rem;">消耗奖励</div> -->
            <div
              class="btnbox"
              :class="flbtnclick === 2 ? 'acbtnbox' : ''"
              @click="changebtn(2)"
              style="margin-top: 0.4rem"
            >
              日常福利
            </div>
            <!-- 奖励说明 -->
            <div class="tipsbox" v-show="flbtnclick === 3">
              奖励说明
              <div class="tips">
                <p>当日参加盲盒对战获得道具[T币]最多的玩家可上对战榜</p>
              </div>
            </div>
          </div>
          <!-- 中间分割 -->
          <div class="suline">
            <img
              style="display: block; width: 0.01rem; height: 100%"
              src="../../assets/images/Rank/pc-rank-cb.png"
              alt=""
            />
          </div>
          <!-- 右边显示区域 -->
          <div class="r_center">
            <!-- 广告区 -->
            <!-- 组件显示区域 -->
            <div class="content">
              <!-- 动态渲染区域 -->
              <!--日常福利组件 -->
              <div v-if="Dailywelfare">
                <!-- 头部按钮 -->
                <div class="erjibtnbox">
                  <div class="acejbtn">
                    <span>红包口令</span>
                  </div>
                </div>
                <!--内容区域 -->
                <div class="line2"></div>
                <div class="contentbox">
                  <div class="CDKput">
                    <input
                      type="text"
                      style="text-align: center"
                      v-model="cdkvalue"
                      placeholder="请输入红包口令"
                    />
                    <div class="getcdk" @click="getred">立即领取</div>
                  </div>
                </div>
              </div>
              <!-- 对战排行组件 -->
              <div v-else-if="competitiveness">
                <!-- 头部按钮 -->
                <div class="erjibtnbox">
                  <div
                    class="erjibtn"
                    :class="changeerjibtn === 1 ? 'acejbtn' : ''"
                    @click="changeerji(1)"
                  >
                    <span>今日排行</span>
                  </div>
                  <div
                    class="erjibtn"
                    :class="changeerjibtn === 2 ? 'acejbtn' : ''"
                    @click="changeerji(2)"
                  >
                    <span>昨日排行</span>
                  </div>
                </div>
                <!--内容区域 -->
                <div class="contentbox">
                  <!-- 今日排行 -->
                  <div v-show="today">
                    <div class="datalisttitle">
                      <div style="width: 50%; text-align: center">
                        <span>会员</span>
                      </div>
                      <div style="width: 50%; text-align: center">
                        <span>金额</span>
                      </div>
                    </div>
                    <div class="datalistbg">
                      <div
                        class="listdata"
                        v-for="item in todaylist"
                        :key="item.user_id"
                      >
                        <!-- 头像名字 -->
                        <div
                          style="
                            width: 50%;
                            text-align: center;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                          "
                        >
                          <img class="userimg" :src="item.user.avatar" alt="" />
                          <span style="width: 50%; color: #cccccc">{{
                            item.user.name
                          }}</span>
                        </div>
                        <!-- 金额 -->
                        <div style="width: 50%; text-align: center">
                          <span style="color: #cccccc">{{ item.expend }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 昨日排行 -->
                  <div v-show="yesterday">
                    <div class="datalisttitle">
                      <div style="width: 50%; text-align: center">
                        <span>会员</span>
                      </div>
                      <div style="width: 50%; text-align: center">
                        <span>金额</span>
                      </div>
                    </div>
                    <div class="datalistbg">
                      <div
                        class="listdata"
                        v-for="item in yesterdaylist"
                        :key="item.user_id"
                      >
                        <!-- 头像名字 -->
                        <div
                          style="
                            width: 50%;
                            text-align: center;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                          "
                        >
                          <img class="userimg" :src="item.user.avatar" alt="" />
                          <span style="width: 50%; color: #cccccc">{{
                            item.user.name
                          }}</span>
                        </div>
                        <!-- 金额 -->
                        <div style="width: 50%; text-align: center">
                          <span style="color: #cccccc">{{ item.expend }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  RedOpen,
  getrecord,
  Info,
  getpointData,
  getNoneReadMsgNum,
} from "@/network/api.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      active: null,
      welfare: false, //福利弹窗

      //福利弹窗内开关
      flbtnclick: 2, //侧边按钮默认点击
      changeerjibtn: 1, //title按钮样式点击
      consume: false, //消耗开关
      Dailywelfare: false, //日常福利开关
      cdkvalue: "", //CDK红包输入值
      competitiveness: false, //排行组件开关
      today: false, //今日排行
      yesterday: false, //昨日排行
      clarification: false, //奖励说明
      todaylist: [], //今日数据
      yesterdaylist: [], //昨日数据

      // 消耗福利数据保存
      day_use: "", //今日消耗
      yesterday_reward: "", //昨日奖励
      limit_reward: "", //限时奖励
      history_reward: "", //历史领取
      join: false, //QQ群开关
      joinkefu: false, //客服开关

      // 客服图片链接，qq群图片跳转链接
      querydata: {
        qq_group_qr_code: "",
        qq_group_link: "",
        service_qr_code: "",
        service_qq: "",
      },
      noneReadNum: 0,
    };
  },
  created() {
    this.getrecorddata();
    this.GetInfo();
    this.getNoneReadMsgNumFun();
  },
  computed: {
    ...mapState(["user"]),
  },
  watch: {
    $route: {
      handler() {
        this.getNoneReadMsgNumFun();
      },
      immediate: true,
    },
  },
  methods: {
    async getNoneReadMsgNumFun() {
      const res = await getNoneReadMsgNum();
      console.log("未读", res);
      if (res.data?.code === 200) {
        this.noneReadNum = res.data.data?.unread_count;
      }
    },
    toMail() {
      if (!this.user.id) {
        this.$message({
          message: "未登录，请登录后操作",
          type: "error",
        });
      } else {
        this.$router.push({
          path: "/Mail",
        });
      }
    },
    async tocdkbox() {
      const params = {
        type: 4,
      };
      const res = await getpointData(params);
      if (res?.data?.code === 200 && res?.data?.data?.length > 0) {
        this.$router.push({
          path: "/OpeningBox",
          query: {
            id: res?.data?.data[0].box_id,
            typeid: res?.data?.data[0].id,
          },
        });
      } else {
        this.$message.error("暂无内容");
      }
    },
    totop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
    GetInfo() {
      Info().then((res) => {
        this.querydata = res.data.data;
      });
    },

    // 选中改变
    acchage(v) {
      this.active = v;
    },
    // 侧边按钮控制
    changebtn(v) {
      this.flbtnclick = v;
      switch (v) {
        case 1:
          this.consume = true;
          this.Dailywelfare = false;
          this.competitiveness = false;
          break;
        case 2:
          this.Dailywelfare = true;
          this.consume = false;
          this.competitiveness = false;
          break;
        case 3:
          this.competitiveness = true;
          if ((this.competitiveness = true)) {
            this.today = true; //今日排行组件
          }
          this.Dailywelfare = false;
          this.consume = false;
          break;
      }
    },
    // title按钮控制
    changeerji(v) {
      this.changeerjibtn = v;
      switch (v) {
        case 1:
          this.today = true;
          this.clarification = false;
          this.yesterday = false;
          break;
        case 2:
          this.yesterday = true;
          this.today = false;
          this.clarification = false;
          break;
      }
    },
    touser() {
      if (!this.user.id) {
        this.$message({
          message: "未登录，请登录后操作",
          type: "error",
        });
      } else {
        this.$router.push({
          path: "/newuser",
          query: {
            TabIndex: 1,
          },
        });
      }
    },
    //获取cdk红包
    getred() {
      if (this.cdkvalue == "") {
        this.$message({
          message: "请输入CDK",
          type: "error",
        });
      } else {
        RedOpen(2, _, this.cdkvalue).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: `领取成功,金额为${res.data.data}`,
              type: "success",
            });
            this.cdkvalue = "";
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
            this.cdkvalue = "";
          }
        });
      }
    },
    // 获得消耗数据
    getrecorddata() {
      getrecord().then((res) => {
        if (res.data.code == 200) {
          this.day_use = res.data.data.day_use;
          this.history_reward = res.data.data.history_reward;
          this.limit_reward = res.data.data.limit_reward;
          this.yesterday_reward = res.data.data.yesterday_reward;
        }
      });
    },
    //显示加群效果
    joincodeenter() {
      this.join = true;
    },
    joincodeleve() {
      this.join = false;
    },
    // 显示客服
    joincodeenterkefu() {
      this.joinkefu = true;
    },
    joincodelevekefu() {
      this.joinkefu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#RANK {
  position: fixed;
  width: 0.8rem;
  top: 2.8rem;
  right: 0.48rem;
  font-size: 0.12rem;
  z-index: 395;
  .iconbox {
    .icon {
      width: 0.75rem;
      margin-top: 0.1rem;
      position: relative;
      .iconcenter {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 0.48rem;
        height: 0.48rem;
      }
      p {
        margin-top: 0.1rem;
        text-align: center;
      }
      .topimg {
        width: 0.28rem;
        height: 0.28rem;
      }
    }
    .tipsicon {
      position: absolute;
      top: 0;
      background: #ff7300;
      // padding: 0.02rem 0.05rem;
      width: 0.24rem;
      height: 0.24rem;
      border-radius: 50%;
      font-weight: 500;
      font-size: 0.12rem;
      color: #ffffff;
    }
    a {
      text-decoration: none;
      color: #fff;
    }
  }

  .joincode {
    position: absolute;
    top: 0;
    right: 0.8rem;

    img {
      display: block;
      width: 2rem;
    }
  }
  // 福利中心盒子
  .duwelfare {
    position: relative;
    top: 20%;
    left: 25%;
    width: 9.9rem;
    height: 6.38rem;
    overflow: hidden;
    background: rgba(8, 8, 30, 0.8);
    border-radius: 0.04rem;
    border: 0.01rem solid #5d5d9b;
    .title {
      width: 100%;
      height: 0.52rem;
      display: flex;
      align-items: center;
      position: relative;

      .center {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 0.2rem;
        color: #ffffff;
        margin: 0 auto;

        img {
          display: block;
          height: 1rem;
        }
      }
    }
    .line {
      width: 100%;
      height: 0;
      border: 0.01rem solid;
      border-image: linear-gradient(
          90deg,
          rgba(93, 93, 155, 0),
          rgba(93, 93, 155, 1),
          rgba(93, 93, 155, 0)
        )
        1 1;
    }
    .line2 {
      width: 100%;
      height: 0;
      border: 0.01rem solid;
      border-image: linear-gradient(
          90deg,
          rgba(93, 93, 155, 1),
          rgba(93, 93, 155, 0)
        )
        1 1;
    }
    .welfaremain {
      display: flex;
      .l_btn {
        width: 2.22rem;
        position: relative;
        .btnbox {
          width: 1.02rem;
          height: 0.36rem;
          border-radius: 0.02rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 0.16rem;
          color: #686892;
          line-height: 0.36rem;
          text-align: center;
          margin: 0.1rem auto;
          background: #0a0b18;
          border: 0.01rem solid #8686db;
        }
        .acbtnbox {
          color: #fff;
          background: linear-gradient(135deg, #242448 100%, #42426d 100%);
        }
        .tipsbox {
          position: absolute;
          bottom: 0;
          left: 15%;
          width: 1.65rem;
          height: 0.8rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 0.14rem;
          color: #adaddf;
          .tips {
            margin-top: 0.1rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 0.12rem;
            color: #8383b8;
          }
        }
      }
      .suline {
        height: 5.86rem;
      }
      .r_center {
        width: 7.68rem;
        .content {
          width: 100%;
          .erjibtnbox {
            width: 100%;
            height: 0.53rem;

            display: flex;
            align-items: center;
            padding-left: 0.28rem;
            .erjibtn {
              margin-right: 0.2rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 0.18rem;
              color: #48485e;
              cursor: pointer;
            }
            .acejbtn {
              color: #9292d7;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 0.18rem;
            }
          }
          .contentbox {
            width: 100%;
            height: 5.33rem;
            // 日常福利
            .CDKput {
              width: 100%;
              height: 4rem;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
              flex-direction: column;
              input {
                width: 80%;
                height: 0.4rem;
                border: 0;
                outline: none;
                background: #08081e;
                border: 0.01rem solid #32325d;
              }

              // 红包
              .getcdk {
                margin-top: 0.2rem;
                width: 1.2rem;
                height: 0.32rem;
                text-align: center;
                line-height: 0.32rem;
                border-radius: 0.04rem;
                background: linear-gradient(135deg, #383876 100%, #222248 100%);
                cursor: pointer;
              }
            }
            .datalisttitle {
              width: 95%;
              height: 0.5rem;
              margin: 0 auto;
              background: url("../../assets/images/NewUser/pc-newuser-newtabletitle.png")
                no-repeat;
              background-size: 100% 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            // 排行css
            .datalistbg {
              width: 95%;
              height: 5.4rem;
              margin: 0rem auto;
              overflow-x: scroll;
              .listdata {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 0.48rem;
                background: #08080f;
                border: 0.01rem solid #2f2f5e;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 0.14rem;
                color: #cccccc;
                .userimg {
                  display: block;
                  width: 0.32rem;
                  height: 0.32rem;
                  border-radius: 50%;
                  margin: 0.03rem;
                }
              }
            }
          }
        }
      }
    }

    // .welfaremain {
    //   width: 100%;
    //   height: 7rem;
    //   background: url("../../assets/images/Rank/bgtu.png") no-repeat;
    //   background-size: 100% 100%;
    //   display: flex;

    //   .r_center {
    //     width: 75%;
    //     height: 100%;
    //     // background-color: chocolate;

    //     .warp {
    //       width: 80%;
    //       height: 0.4rem;
    //       background: url("../../assets/images/Rank/warp.png") no-repeat;
    //       background-size: 100% 100%;
    //       text-align: center;
    //       line-height: 0.4rem;
    //       margin-top: 0.15rem;
    //       margin-left: 0.2rem;
    //     }

    //     .content {
    //       width: 100%;
    //       height: 100%;
    //       padding-top: 0.2rem;

    //       .erjibtnbox {
    //         width: 100%;
    //         display: flex;
    //         align-items: center;
    //         padding-left: 0.2rem;
    //         margin-top: 0.1rem;
    //         cursor: pointer;

    //         .erjibtn {
    //           width: 1.45rem;
    //           height: 0.4rem;
    //           text-align: center;
    //           line-height: 0.4rem;
    //           color: #0080ff;
    //           background: url("../../assets/images/Rank/ejbtn.png") no-repeat;
    //           background-size: 100% 100%;
    //         }

    //         .acejbtn {
    //           width: 1.45rem;
    //           height: 0.4rem;
    //           color: #fff;
    //           text-align: center;
    //           line-height: 0.4rem;
    //           background: url("../../assets/images/Rank/acejbtn.png") no-repeat;
    //           background-size: 100% 100%;
    //         }
    //       }

    //       .contentbox {
    //         width: 96%;
    //         height: 100%;
    //         border-top: 2px solid #0080ff;
    //         margin: 0.1rem auto;

    //         // 消耗奖励
    //         .progresst {
    //           width: 100%;
    //           height: 30%;
    //           margin-top: 0.1rem;
    //           padding-right: 0.3rem;
    //           padding-left: 0.2rem;

    //           p {
    //             margin: 0;
    //             height: 0.3rem;
    //           }
    //         }

    //         // 日常福利
    //         .CDKput {
    //           width: 100%;
    //           height: 5rem;
    //           display: flex;
    //           justify-content: center;
    //           align-items: center;
    //           flex-wrap: wrap;
    //           flex-direction: column;

    //           // background-color: #0080ff;
    //           input {
    //             width: 80%;
    //             height: 0.4rem;
    //             // background-color: #0080ff;
    //             border: 0;
    //             outline: none;
    //             background: url("../../assets/images/Rank/cdk.png") no-repeat;
    //             background-size: 100% 100%;
    //           }

    //           // 红包
    //           .getcdk {
    //             margin-top: 0.2rem;
    //             width: 1.2rem;
    //             height: 0.4rem;
    //             text-align: center;
    //             line-height: 0.4rem;
    //             background: url("../../assets/images/Rank/qr.png") no-repeat;
    //             background-size: 100% 100%;
    //             cursor: pointer;
    //           }
    //         }

    //         .datalisttitle {
    //           width: 95%;
    //           height: 0.5rem;
    //           margin: 0.1rem auto 0 auto;
    //           background-color: #003366;
    //           display: flex;
    //           justify-content: center;
    //           align-items: center;
    //         }

    //         // 排行css
    //         .datalistbg {
    //           width: 95%;
    //           height: 5.4rem;
    //           margin: 0rem auto;
    //           background: url("../../assets/images/Rank/listbg.png") no-repeat;
    //           background-size: 100% 100%;
    //           overflow-x: scroll;
    //           .listdata {
    //             display: flex;
    //             justify-content: center;
    //             align-items: center;
    //             height: 0.5rem;
    //             margin-bottom: 0.04rem;

    //             .userimg {
    //               display: block;
    //               width: 12%;
    //               height: 0.5rem;
    //               border-radius: 50%;
    //               margin: 0.03rem;
    //             }
    //           }
    //         }

    //         .tips {
    //           width: 100%;
    //           height: 3rem;
    //           display: flex;
    //           justify-content: center;
    //           align-items: center;
    //         }
    //       }
    //     }
    //   }
    // }
  }
  .jump {
    animation: bounce 2s infinite;
  }
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-0.3rem); /* 上升的距离 */
    }
    100% {
      transform: translateY(0);
    }
  }
}
</style>
